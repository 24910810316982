import { useState } from "react";
import { LocaleIcon } from "@components/LocaleIcon";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@components/Select";
import { setSessionCookie } from "@libs/cookie";

export function LocaleSelect({
  defaultLocale,
  locales,
}: {
  defaultLocale: string;
  locales: {
    label: string;
    value: string;
    url: string;
  }[];
}) {
  const [value, setValue] = useState<string>(defaultLocale);

  const onSelect = (value: string) => {
    setValue(value);

    setSessionCookie("locale", value);

    const store = locales.find((locale) => locale.value === value);
    window.open(store?.url, "_self");

    window.plausible("ChangeLanguage", {
      props: {
        method: "HTTP",
        Language: value === "en" ? "English" : "French",
      },
    });
  };

  return (
    <Select onValueChange={onSelect} value={value}>
      <SelectTrigger className="w-fit">
        <>
          <LocaleIcon className="w-4 h-4" />
          <SelectValue />
        </>
      </SelectTrigger>
      <SelectContent>
        {locales.map((locale) => (
          <SelectItem key={locale.value} value={locale.value}>
            {locale.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
