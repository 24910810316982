export const LocaleIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.43317 10.4468C8.5265 10.2068 8.4665 9.9335 8.27984 9.74683L6.8865 8.3735L6.9065 8.3535C8.0665 7.06016 8.89317 5.5735 9.37984 4.00016H10.6732C11.0332 4.00016 11.3332 3.70016 11.3332 3.34016V3.32683C11.3332 2.96683 11.0332 2.66683 10.6732 2.66683H6.6665V2.00016C6.6665 1.6335 6.3665 1.3335 5.99984 1.3335C5.63317 1.3335 5.33317 1.6335 5.33317 2.00016V2.66683H1.3265C0.966504 2.66683 0.666504 2.96683 0.666504 3.32683C0.666504 3.6935 0.966504 3.98683 1.3265 3.98683H8.11317C7.6665 5.28016 6.95984 6.50016 5.99984 7.56683C5.45984 6.9735 5.0065 6.32683 4.6265 5.64683C4.51984 5.4535 4.3265 5.3335 4.1065 5.3335C3.6465 5.3335 3.35317 5.8335 3.57984 6.2335C3.99984 6.98683 4.51317 7.70683 5.11317 8.3735L2.19984 11.2468C1.93317 11.5068 1.93317 11.9335 2.19984 12.1935C2.45984 12.4535 2.87984 12.4535 3.1465 12.1935L5.99984 9.3335L7.3465 10.6802C7.6865 11.0202 8.2665 10.8935 8.43317 10.4468ZM11.6665 6.66683C11.2665 6.66683 10.9065 6.9135 10.7665 7.2935L8.31984 13.8268C8.15984 14.2335 8.4665 14.6668 8.89984 14.6668C9.15984 14.6668 9.39317 14.5068 9.4865 14.2602L10.0798 12.6668H13.2465L13.8465 14.2602C13.9398 14.5002 14.1732 14.6668 14.4332 14.6668C14.8665 14.6668 15.1732 14.2335 15.0198 13.8268L12.5732 7.2935C12.4265 6.9135 12.0665 6.66683 11.6665 6.66683ZM10.5865 11.3335L11.6665 8.44683L12.7465 11.3335H10.5865Z"
        fill="currentColor"
      />
    </svg>
  );
};
